import firebase from 'firebase';
import Vue from "vue";
import Router from "vue-router";
import store from './store.js'

// Public pages initial load
// NOTE: Any pre-rendered pages must be here!
import PublicHeader from "./layout/PublicHeader";
import Landing from "./views/Landing.vue";
import LifetimeOffer from "./views/LifetimeOffer.vue";
import Booking from "./views/Booking.vue";
import Footer from "./layout/AppFooter";

// App Header (logged in)
const Admin = () => import(/* webpackChunkName: "admin-app" */ './views/Admin.vue')

const Login = () => import(/* webpackChunkName: "auth" */ './components/auth/Login.vue')
const ForgotPassword = () => import(/* webpackChunkName: "auth" */ './components/auth/ForgotPassword.vue')
const Register = () => import(/* webpackChunkName: "auth" */ './components/auth/Register.vue')
const Invites = () => import(/* webpackChunkName: "auth" */ './components/auth/Invites.vue')
const GroupInvites = () => import(/* webpackChunkName: "auth" */ './components/auth/GroupInvites.vue')
const Subscription = () => import(/* webpackChunkName: "auth" */ './views/Subscription.vue')

const Welcome = () => import(/* webpackChunkName: "onboarding" */ './views/Welcome.vue')

const ReflectNow = () => import(/* webpackChunkName: "core-app" */ './views/ReflectNow.vue')
const ReflectionDetails = () => import(/* webpackChunkName: "core-app" */ './views/ReflectionDetails.vue')
const Weekly = () => import(/* webpackChunkName: "core-app" */ './views/Weekly.vue')
const DeepDive = () => import(/* webpackChunkName: "core-app" */ './views/DeepDive.vue')
const Profile = () => import(/* webpackChunkName: "core-app" */ './views/Profile.vue')

const MyGroups = () => import(/* webpackChunkName: "core-app" */ './views/MyGroups.vue')
const ViewGroup = () => import(/* webpackChunkName: "core-app" */ './components/mygroups/View.vue')
const SharedReport = () => import(/* webpackChunkName: "core-app" */ './views/SharedReport.vue')
const ManageGroups = () => import(/* webpackChunkName: "core-app" */ './views/ManageGroups.vue')

const AppHeader = () => import(/* webpackChunkName: "layout" */ './layout/AppHeader.vue')

const LinkTree = () => import(/* webpackChunkName: "public-app" */ './views/LinkTree.vue')
const Page = () => import(/* webpackChunkName: "public-app" */'./views/Page.vue')
const Announcement = () => import(/* webpackChunkName: "public-app" */'./views/Announcement.vue')
const WrongPage = () => import(/* webpackChunkName: "public-app" */'./views/WrongPage.vue')


Vue.use(Router);

const ifAuthenticatedAsAdmin = (to, from, next) => {

  if (store.getters.user.administrativeUser) {
    return next();
  }
  return next('/login');

};

const router = new Router({
  linkExactActiveClass: "active",
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "homepage",
      components: {
        appheader: PublicHeader,
        default: Landing,
        appfooter: Footer
      }
    },
    {
      path: "/landing",
      name: "landing",
      components: {
        appheader: PublicHeader,
        default: Landing,
        appfooter: Footer
      }
    },
    {
      path: "/lifetime-offer",
      name: "lifetime-offer",
      components: {
        appheader: PublicHeader,
        default: LifetimeOffer,
        appfooter: Footer
      }
    },
    {
      path: "/linktree",
      name: "linktree",
      components: {
        appheader: PublicHeader,
        default: LinkTree,
        appfooter: Footer
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        appheader: PublicHeader,
        default: Login,
        appfooter: Footer
      }
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      components: {
        appheader: PublicHeader,
        default: ForgotPassword,
        appfooter: Footer
      }
    },
    {
      path: "/register",
      name: "register",
      components: {
        appheader: PublicHeader,
        default: Register,
        appfooter: Footer
      }
    },
    {
      path: "/profile",
      name: "profile",
      components: {
        appheader: AppHeader,
        default: Profile,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/reflectnow",
      name: "reflectnow",
      components: {
        appheader: AppHeader,
        default: ReflectNow,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: "/reflectnow/:id",
      name: "reflection-details",
      components: {
        appheader: AppHeader,
        default: ReflectionDetails,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: "/weekly",
      name: "weekly",
      components: {
        appheader: AppHeader,
        default: Weekly,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: "/weekly/:startDate",
      name: "weekly-dated",
      components: {
        appheader: AppHeader,
        default: Weekly,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: "/deep-dives",
      name: "deep-dives",
      components: {
        appheader: AppHeader,
        default: DeepDive,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: "/deep-dives/:id/:action",
      name: "deep-dives-create",
      components: {
        appheader: AppHeader,
        default: DeepDive,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: "/deep-dives/:id",
      name: "deep-dives-report",
      components: {
        appheader: AppHeader,
        default: DeepDive,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: "/subscription",
      name: "subscription",
      components: {
        appheader: AppHeader,
        default: Subscription,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },

    {
      path: "/mygroups",
      name: "mygroups",
      components: {
        appheader: AppHeader,
        default: MyGroups,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },

    {
      path: "/mygroups/:guid",
      name: "viewgroup",
      components: {
        appheader: AppHeader,
        default: ViewGroup,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },

    {
      path: "/managegroups",
      name: "managegroups",
      components: {
        appheader: AppHeader,
        default: ManageGroups,
        appfooter: Footer
      },
      meta: {
        requiresAuth: true
      },
    },

    {
      path: "/joinmygroup/:guid/:key",
      name: "group-invite-acceptance",
      components: {
        appheader: PublicHeader,
        default: GroupInvites,
        appfooter: Footer
      }
    },

    {
      path: "/invite/:guid/:iuid",
      name: "invite-acceptance",
      components: {
        appheader: PublicHeader,
        default: Invites,
        appfooter: Footer
      }
    },
    
    {
      path: "/shared/:uuid",
      name: "shared",
      components: {
        appheader: PublicHeader,
        default: SharedReport,
        appfooter: Footer
      }
    },
    {
      path: "/about",
      name: "about",
      components: {
        appheader: PublicHeader,
        default: Page,
        appfooter: Footer
      }
    },
    {
      path: "/reflection-week",
      name: "reflection-week",
      components: {
        appheader: PublicHeader,
        default: Booking,
        appfooter: Footer
      }
    },
    {
      path: "/reflection-intro",
      name: "reflection-intro",
      components: {
        appheader: PublicHeader,
        default: Booking,
        appfooter: Footer
      }
    },
    {
      path: "/welcome",
      name: "welcome",
      components: {
        // appheader: AppHeader,
        default: Welcome,
        // appfooter: Footer
      }
    },
    {
      path: "/book-time-with-chris",
      name: "book-time-with-chris",
      components: {
        appheader: PublicHeader,
        default: Booking,
        appfooter: Footer
      }
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      components: {
        appheader: PublicHeader,
        default: Page,
        appfooter: Footer
      }
    },
    {
      path: "/terms-and-conditions",
      name: "terms-and-conditions",
      components: {
        appheader: PublicHeader,
        default: Page,
        appfooter: Footer
      }
    },
    {
      path: "/announcements/:slug",
      name: "announcements",
      components: {
        appheader: PublicHeader,
        default: Announcement,
        appfooter: Footer
      }
    },
    {
      path: "/admin",
      name: "admin",
      components: {
        appheader: AppHeader,
        default: Admin,
        appfooter: Footer
      },
      beforeEnter: ifAuthenticatedAsAdmin
    },
    {
      path: "*",
      name: "other",
      components: {
        appheader: PublicHeader,
        default: WrongPage,
        appfooter: Footer
      }
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {

      const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
      const loggedIn = store.getters.user.loggedIn

      firebase.analytics().logEvent('page_view', { type: 'internal' });

      // IF page requires auth and the user isn't logged in
      if (requiresAuth && !loggedIn) next('/login');

      // Commented out so that logged in users could still see /landing and /faq
      // else if (!requiresAuth && loggedIn) next('reflectnow');

      // If a logged in user accesses / then route to reflectnow
      else if (loggedIn && to.name === 'homepage') next('reflectnow');

      else next();

});


export default router;
