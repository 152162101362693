<template>
  <div>
    <!-- HERO INTRO -->
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 bg-gradient-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row justify-content-center">
              <div class="col-lg-8 text-center mb-5">

                <h1 class="offer-title text-white mb-5">
                  <span>Get our Exclusive</span><br>
                  <span><span class="lifetime-offer font-weight-bold">Lifetime deal</span> 🥳</span>
                </h1>

              </div>
              <div class="col-8 text-center mt-5">

                  <h3 class="display-4 text-white mt-5">
                    Pay once and reflect effectively forever!
                  </h3>
                  <p class="lead text-white">
                      Instead of our regular pricing, we’re offering you<br/>
                      an exclusive <span class="lifetime-offer">lifetime deal</span> for a limited time only
                  </p>

              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <!-- BUY NOW! -->
    <buy-now top-margin="mt--300"></buy-now>

    <!-- What do you get with your lifetime subscription? -->
    <section class="section bg-secondary my-0 overflow-hidden">
      <div class="container pt-sm pb-sm">
        <div class="row justify-content-center">

          <div class="col-lg-8">
              <h3 class="text-default text-center feature-title">
                What do you get with your <span class="text-primary lifetime-offer font-weight-bold">lifetime</span> subscription?
              </h3>
          </div>

        </div>

      </div>
    </section>

    <feature 
        bg="bg-gradient-default"
        image="img/screens/reflect-now.png"
        type="dark"
        image-class=""
        view="textfirst"
        >

        <template v-slot:title>
          Reflect Now
        </template>

        <template v-slot:subtext>
          Capture timely, consistent reflections
        </template>

        <template v-slot:contentbody>
          My Reflections empowers you to capture activity details
          for more timely reflection and to begin the process of
          identifying high-lights and low-lights for future, deep
          reflection sessions through your weekly reflections.
        </template>

    </feature>

    <feature 
        bg="bg-gradient-white"
        image="img/screens/activity-types.png"
        type="light"
        image-class="rounded shadow"
        view="textsecond"
        >

        <template v-slot:title>
          Activity Types &amp; Tags
        </template>

        <template v-slot:subtext>
          Log reflections by activity types &amp; your own tags
        </template>

        <template v-slot:contentbody>
          Activity types allow you to categorise activities logically
          and will give insights into overall areas of possible
          improvement. Additionally, you can create your own tags
          for more granular insights and tag your activities accordingly.
        </template>

        <!-- <template v-slot:extra>

          <div class="col-12 order-md-3 text-center">

            <a href="https://www.myreflections.app" target="_blank">

              <base-button type="secondary">
                Learn more
              </base-button>

            </a>

          </div>
          
        </template> -->

    </feature>

    <feature 
        bg="bg-gradient-primary"
        image="img/screens/weekly-reflections.png"
        type="dark"
        image-class=""
        view="textfirst"
        >

        <template v-slot:title>
          Weekly Reflections
        </template>

        <template v-slot:subtext>
          Take weekly deep dives &amp; retrospectives
        </template>

        <template v-slot:contentbody>
          Deep dives, as part of your personal weekly retrospectives, allow
          you to review your weeks' activities, refection statistics 
          and trends while answering consistent questions and identifying
          your weekly highlights, lowlights and adjustments for the upcoming week.
        </template>

    </feature>

    <feature 
        bg="bg-gradient-white"
        image="img/screens/group-reflections.png"
        type="light"
        image-class=""
        view="textsecond"
        >

        <template v-slot:title>
          Group Reflections
        </template>

        <template v-slot:subtext>
          Start discussions &amp; reflect with your groups
        </template>

        <template v-slot:contentbody>
          As a lifetime subscriber you can create a group and invite
          friends, colleagues or family to join you on your reflection
          journey; sharing insights, motivational quotes and images,
          and running quizzes and encouraging each other with comments &amp; reactions.
        </template>

    </feature>

    <feature 
        bg="bg-gradient-dark"
        image="img/screens/reflection-bot.png"
        type="dark"
        image-class=""
        view="textfirst"
        >

        <template v-slot:title>
          <i class="fad fa-robot"></i> Reflection Bot
        </template>

        <template v-slot:subtext>
          Use A.I to for deeper insights &amp; reflections
        </template>

        <template v-slot:contentbody>

          Using social and linguistic algorithms powered by A.I and
          machine learning technologies, <i class="fad fa-robot"></i> Reflection Bot
          will provide you deeper insights into the tone, polarity
          and subjectivity of your reflections so that you can challenge yourself more 
          effectively on your decisions and their outcomes.

        </template>

    </feature>

    <feature 
        bg="bg-gradient-secondary"
        image="img/screens/reflection-automation.png"
        type="light"
        image-class=""
        view="textsecond"
        >

        <template v-slot:title>
          <i class="fad fa-plug"></i> Reflection Automation
        </template>

        <template v-slot:subtext>
          Automate your reflections to make life easier
        </template>

        <template v-slot:contentbody>

          Connect My Reflections to any service that supports outbound
          webhooks to automatically create a reflection for you when you;
          finish an appointment, complete a task, make a sale, complete a workout
          or a run to name a few! You will then be notified to complete
          the reflection if required.

        </template>

    </feature>

    <!-- What are people saying about My Reflections? -->
    <what-people-say />

    <!-- FAQ -->
    <section class="section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-3 bg-gradient-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container pt-lg pb-lg">
        <div class="row text-center justify-content-center">
          <div class="col-lg-10 mb-5">
            <h2 class="text-white feature-title">Frequently Asked Questions</h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10">

              <ul class="list-group list-group-flush bg-none">

                <li 
                  class="list-group-item text-white bg-none border-0"
                    v-for="(faq, qi) in sortedFAQ"
                    v-bind:key="qi"
                >
                  <div class="faq-question text-white pointer" @click="showAnswer(qi)">
                    <div class="float-right">
                      <i class="fal fa-chevron-up small" v-if="shownAnswer !== qi"></i>
                      <i class="fal fa-chevron-down small" v-else></i>
                    </div>
                    {{ faq.name}}
                  </div>
                  <transition name="slide">
                      <div class="faq-answer text-secondary" v-if="shownAnswer === qi">
                        <hr>
                        <rich-text-renderer :document="faq.content.long_text.content" />
                        <hr>
                      </div>
                  </transition>
                </li>

              </ul>

          </div>
        </div>
      </div>
    </section>

    <buy-now top-margin=""></buy-now>

  </div>
</template>

<script>
import Reflection from "@/components/reflections/Reflection";
import BuyNow from "@/components/landing/BuyNow";
import Feature from "@/components/landing/Feature";
import Lifetime from "@/components/billing/Lifetime";
import WhatPeopleSay from '@/components/landing/WhatPeopleSay.vue';
import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";

import StoryblokClient from 'storyblok-js-client'

const token = '6OPOpbp82sAZOEePnmeozgtt';
let storyapi = new StoryblokClient({
  accessToken: token
})  

export default {
  name: "lifetime-landing-page",
  metaInfo() {
    return {
      title: "Lifetime Offer 🥳",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: "Lifetime Offer 🥳",
        },
        {
          vmid: "description",
          name: "description",
          content:
            "Pay once and reflect effectively forever!",
        },
        // FACEBOOK / GRAPH
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://myreflections.app/lifetime-offer",
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: "Lifetime Offer 🥳",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content:
            "Pay once and reflect effectively forever!",
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: "https://myreflections.app/img/banner-lifetime.png",
        },
        // TWITTER
        {
          vmid: "twitter:card",
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          vmid: "twitter:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "twitter:url",
          property: "twitter:url",
          content: "https://myreflections.app/lifetime-offer",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: "Lifetime Offer 🥳",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content:
            "Pay once and reflect effectively forever!",
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: "https://myreflections.app/img/banner-lifetime.png",
        },
      ],
    }
  },
  components: {
    Reflection,
    Lifetime,
    BuyNow,
    Feature,
    WhatPeopleSay
  },
  data() {
    return {
      step: 1,
      shownAnswer: null,
      faqs: []
    };
  },

  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),

    sortedFAQ() {
      let tmpFAQs = this.faqs
      return tmpFAQs.sort((a, b) => (a.position > b.position) ? 1 : -1)
    }

  },
  mounted() {
      this.getFAQs();
  },
  created () {
      if(!window.storyblok){
          window.storyblok.init({
              accessToken: token
          })
      }
  },
  methods: {

    showAnswer(index) {

      if(this.shownAnswer === index){
        this.shownAnswer = null;
      }else{
        this.shownAnswer = index;
      }
      
    },

    getFAQs(){

        const vm = this

        storyapi.get('cdn/stories/', {
            "starts_with": "faq-lifetime/"
        })
        .then(response => {

            let tmpQuestion = response.data.stories
            tmpQuestion.forEach(record => {

                let questionFound = false
                let tmpQuestions = vm.faqs
                tmpQuestions.forEach(q => {
                    if(q.uuid === record.uuid){
                        questionFound = true
                    }
                });

                record.position = Number(record.content.order); 

                if(!questionFound){
                    vm.faqs.push(record)
                }

            });
            
            // console.log(response)
        }).catch(error => { 
            // console.log(error)
        })

    }

  }
  // showAnswer

};
</script>

<style scoped>

  .border-avatar {
    border: 2px solid #8d6c9f;
  }

  .lifetime-offer {
    text-decoration: underline;
    text-decoration-color: #553069;
    text-underline-offset: .15em;
  }

  .offer-title{
    font-size: 6.5vh;
    font-weight: 300;
  }

  .feature-title{
    font-size: 5vh;
    font-weight: 500;
    line-height: 1.2em;
    font-family: 'Caveat';
  }

  .offer-sub-text{
    font-size: 1.2em;
    font-weight: 300;
  }

  .faq-question{
    font-size: 2vh;
    font-weight: 300;
    line-height: 1.2em;
  }

  .faq-answer {
    font-size: 2.5vh;
    font-weight: 300;
    line-height: 1.2em;
    font-style: italic;
  }

  .bg-none {
    background: transparent;
  }

</style>