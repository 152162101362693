<template>
    <div class="header-global" v-if="user">
        <base-nav class="navbar-main" transparent type="" effect="light" expand>
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/reflectnow">
                <img src="/img/brand/white.svg" alt="my refletions">
            </router-link>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                    <router-link slot="brand" class="navbar-brand mr-lg-5" to="/reflectnow">
                        <img src="/img/brand/purple.svg" alt="my refletions">
                    </router-link>
                </div>
                <div class="col-6 collapse-close">
                    <close-button @click="closeMenu"></close-button>
                </div>
            </div>

            <ul class="navbar-nav align-items-lg-center ml-lg-auto" v-if="!user.loggedIn">

                <!-- MENU FOR LARGE DEVICES -->
                <li class="nav-item d-lg-block d-none ml-lg-4">

                    <router-link to="/login" class="text-white">
                        <i class="fad fa-sign-in"></i> <span class="nav-link-inner--text">Login</span>
                    </router-link>

                </li>
                <li class="nav-item d-lg-block d-none ml-lg-4">

                    <router-link to="/register" class="text-white">
                        <i class="fad fa-user-plus"></i> <span class="nav-link-inner--text">Register</span>
                    </router-link>

                </li>

                <li class="nav-item d-lg-none d-md-block ml-lg-4">
                    <router-link to="/login" class="nav-link">
                        <i class="fad fa-sign-in"></i> Login
                    </router-link>
                </li>

                <li class="nav-item d-lg-none d-md-block ml-lg-4">
                    <router-link to="/register" class="nav-link">
                        <i class="fad fa-user-plus"></i> Register
                    </router-link>
                </li>

            </ul>

            <ul class="navbar-nav align-items-lg-center ml-lg-auto" v-else>

                <!-- MENU FOR LARGE DEVICES -->
                <li class="nav-item d-lg-block d-none ml-lg-4">
                    <router-link to="/reflectnow" class="text-white">
                        <i class="fad fa-head-side-brain"></i> <span class="nav-link-inner--text">Reflect Now</span>
                    </router-link>
                </li>


                <!-- MOBILE MENU -->
                <li class="nav-item d-lg-none d-md-block ml-lg-4">
                    <router-link to="/reflectnow" class="nav-link">
                        <i class="fad fa-head-side-brain"></i> Reflect Now
                    </router-link>
                </li>

            </ul>


        </base-nav>
    </div>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";

import { mapGetters } from "vuex";

export default {
    components: {
        BaseNav,
        CloseButton,
        BaseDropdown
    },
    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),
    }
};
</script>
<style>
</style>
