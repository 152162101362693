<template>
    <footer class="footer">

        <div class="container mb-5">
            <div class="row row-grid align-items-center my-md" v-if="!user.loggedIn">
                <div class="col-lg-6">
                    <h3 class="text-primary font-weight-light mb-2">Thank you for supporting us!</h3>
                    <h4 class="mb-0 font-weight-light">Let's get in touch on any of these platforms.</h4>
                </div>
                <div class="col-lg-6 text-lg-right btn-wrapper">
                    <a target="_blank" href="https://twitter.com/myreflections20"
                       class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg" data-toggle="tooltip"
                       data-original-title="Follow us">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a target="_blank" href="https://www.facebook.com/myreflectionsapp"
                       class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" data-toggle="tooltip"
                       data-original-title="Like us">
                        <i class="fab fa-facebook-square"></i>
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/my-reflections"
                       class="btn btn-neutral btn-icon-only btn-linkedin btn-round btn-lg" data-toggle="tooltip"
                       data-original-title="Like us">
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-4 col-12">
                    <div class="copyright text-center text-md-left">
                        &copy; {{year}}
                        <a href="https://www.myreflections.app" target="_blank" rel="noopener">My Reflections</a>
                        
                    </div>
                </div>
                <div class="col-md-8 col-12">
                    <ul class="nav nav-footer justify-content-md-end justify-content-center">
                        <!-- <li class="nav-item">
                            <a href="https://www.chriswinfieldblum.com" class="nav-link" target="_blank" rel="noopener">Chris Winfield-Blum</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <router-link to="/about" title="About My Reflections" class="nav-link">
                                About
                            </router-link>
                        </li> -->
                        <li class="nav-item">
                            <a href="https://stories.myreflections.app/" target="_blank" title="Reflection Stories" class="nav-link">
                                Reflection Stories
                            </a>
                        </li>
                        <li class="nav-item">
                            <router-link to="/privacy-policy" title="Privacy Policy" class="nav-link">
                                Privacy Policy
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/terms-and-conditions" title="erms and Conditions" class="nav-link">
                                Terms &amp; Conditions
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(Tawk_API.toggle())" class="nav-link">
                                Get Help
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'starter-footer',
  props: ['user'],
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
