import Vue from "vue";
import Vuex from "vuex";
import { db } from '@/firebase';
import createPersistedState from 'vuex-persistedstate'
// import { use } from "vue/types/umd";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        user: {
            androidDetected: false,
            loggedIn: null,
            data: null,
            subscription: null,
            administrativeUser: false
        },
        groups: [],
        reflectionActions: {
            deleted: [],
            updated: []
        },
        reflectionQueue: []
    },
    getters: {
        user(state){
            return state.user
        },
        groups(state){
            return state.groups
        },
        reflectionActions(state){
            return state.reflectionActions
        },
        reflectionQueue(state){
            return state.reflectionQueue
        }
    },
    mutations: {

        SET_ANDROID_DETECTED(state, value) {
            state.user.androidDetected = value;
        },

        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },

        SET_ADMIN(state, value) {
            state.user.administrativeUser = value;
        },

        SET_USER(state, data) {
            state.user.data = data;
        },

        REFLECTION_QUEUE_ADD(state, data) {
            state.reflectionQueue.push(data);
        },

        REFLECTION_QUEUE_REMOVE(state, index) {
            
            if (index !== -1) {
                state.reflectionQueue = [];
            }

        },

        REFLECTION_DELETED(state, data) {
            state.reflectionActions.deleted.push(data);
        },

        REFLECTION_REMOVE_DELETED(state, data) {

            var index = state.reflectionActions.deleted.indexOf(data);
            if (index !== -1) {
                state.reflectionActions.deleted.splice(index, 1);
            }

        },

        REFLECTION_UPDATED(state, data) {
            state.reflectionActions.updated.push(data);
        },

        REFLECTION_REMOVE_UPDATED(state, data) {

            var index = state.reflectionActions.updated.indexOf(data);
            if (index !== -1) {
                state.reflectionActions.updated.splice(index, 1);
            }

        },

        SET_SUBSCRIPTION(state, data) {

            state.user.subscription = {
                maxUsers: data.maxUsers,
                maxGroups: data.maxGroups,
                quantity: data.quantity,
                tier: 1,
                status: 1,
                commenced: data.commenced,
                expires: data.expires,
                sponsored: data.sponsored,
                lifetime: data.lifetime
            };

        },

        CLEAR_SUBSCRIPTION(state, data) {
            state.user.subscription = {
                maxUsers: 0,
                maxGroups: 0,
                quantity: 0,
                tier: 0,
                status: 0,
                sponsored: false,
                lifetime: false
            };
        },

        CLEAR_GROUPS(state, data){
            state.groups = [];
        },

        CHANGE_GROUPS: (state, { newIndex, oldIndex, doc, type }) => {
            if (type === 'added') {
              state.groups.splice(newIndex, 0, doc.data())
            } else if (type === 'modified') {
              state.groups.splice(oldIndex, 1)
              state.groups.splice(newIndex, 0, doc.data())
            } else if (type === 'removed') {
              state.groups.splice(oldIndex, 1)
            }
        },

    },
    actions: {

        updateUser({ commit },user){

            let userId = user.uid
            let newData = user
            db.collection("users").doc(userId).update(newData);
            // commit("SET_USER", newData);

        },

        fetchUser({ commit }, user) {

            commit("SET_LOGGED_IN", user !== null);

            if (user) {

                const docRef = db.collection("users").doc(user.uid);
                docRef.onSnapshot( async function(doc) {

                    let thisUser = doc.data()
                    commit("SET_USER", thisUser);

                    if(thisUser.admin){
                        commit("SET_ADMIN", true);
                    }else{
                        commit("SET_ADMIN", false);
                    }

                    let subCount = 0
                    let commenced = null
                    let expires = null
                    let sponsored = false
                    let lifetime = false

                    // commit("CLEAR_SUBSCRIPTION", null);

                    const subscriptions = await doc.ref.collection('subscriptions').where('status', 'in', ['active', 'trialing']).get();
                    subscriptions.docs.forEach((doc) => {
                        commenced = doc.data().created
                        expires = doc.data().current_period_end
                        subCount += doc.data().quantity;
                    });

                    if(thisUser && thisUser.subscriptionDetails && thisUser.subscriptionDetails.sponsored){
                        sponsored = true
                    }

                    if(sponsored || subCount > 0){

                        if(thisUser.subscriptionDetails && thisUser.subscriptionDetails.expires){
                            // console.log('we found a expires override')
                            expires = thisUser.subscriptionDetails.expires
                        }
                        
                        if(thisUser.subscriptionDetails && thisUser.subscriptionDetails.commenced){
                            // console.log('we found a commenced override')
                            commenced = thisUser.subscriptionDetails.commenced
                        }

                        if(thisUser.subscriptionDetails && thisUser.subscriptionDetails.lifetime){
                            // console.log('we found a lifetime override')
                            lifetime = thisUser.subscriptionDetails.lifetime
                        }

                        let maxGroups = subCount * 2
                        if(thisUser.subscriptionDetails && thisUser.subscriptionDetails.maxGroups){
                            // console.log('we found a maxGroup override')
                            maxGroups = thisUser.subscriptionDetails.maxGroups
                        }

                        let maxUsers = subCount * 5
                        if(thisUser.subscriptionDetails && thisUser.subscriptionDetails.maxUsers){
                            // console.log('we found a maxUsers override')
                            maxUsers = thisUser.subscriptionDetails.maxUsers
                            subCount = 1
                        }

                        commit("SET_SUBSCRIPTION", {
                            commenced: commenced,
                            expires: expires,
                            quantity: subCount,
                            maxGroups: maxGroups,
                            maxUsers: maxUsers,
                            sponsored: sponsored,
                            lifetime: lifetime,
                        });

                    }

                }, function(error) {
                    // console.log(error)
                    commit("SET_ADMIN", null);
                    commit("SET_USER", null);
                    commit("CLEAR_SUBSCRIPTION", null);
                });

                commit("CLEAR_GROUPS", null);

                db.collection("groups").where('uids', 'array-contains',  user.uid ).orderBy('groupName', "asc")
                    .onSnapshot(function(snapshot) {
                        snapshot.docChanges().forEach(function(change) {

                            const { newIndex, oldIndex, doc, type } = change
                            commit('CHANGE_GROUPS', { newIndex, oldIndex, doc, type })

                        });
                    });

            
            }else{
                commit("SET_USER", null);
                commit("CLEAR_SUBSCRIPTION", null);
                commit("CLEAR_GROUPS", null);
            }

        }


    }
});