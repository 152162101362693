export default [
    {
        tier: 0,
        name: 'free',
        paymentType: 'none',
        features: [
            { 
                name: 'FREE FOREVER',
                available: true
            },
            { 
                name: '5 Reflections per day',
                available: true
            },
            { 
                name: 'Reflections',
                available: true
            },
            { 
                name: 'Photo Journals',
                available: false
            },
            {
                name: 'Weekly Reports',
                available: true
            },
            {
                name: 'Shared Reports',
                available: true
            },
            {
                name: 'Comments & Feedback',
                available: true
            },
            {
                name: 'My Groups',
                available: 'partial',
                label: 'You can be invited to groups'
            },
            {
                name: 'Group Feed',
                available: 'partial',
                label: 'You can be invited to groups'
            },
            {
                name: 'Group Reports',
                available: 'partial',
                label: 'If you are part of an open group you can view Group Reports'
            },
            {
                name: 'Create & Manage Groups',
                available: false
            },
            {
                name: 'Send Group Notifications',
                available: false
            }
        ]
    },
    {
        tier: 1,
        name: 'professional',
        paymentType: 'subscription',
        features: [
            { 
                name: 'FREE 7 DAY TRIAL',
                available: true
            },
            { 
                name: '$AUD 4.99 per month for 5 members',
                available: true
            },
            { 
                name: 'Unlimited Reflections',
                available: true
            },
            { 
                name: 'Reflections',
                available: true
            },
            { 
                name: 'Photo Journals',
                available: true
            },
            {
                name: 'Weekly Reports',
                available: true
            },
            {
                name: 'Shared Reports',
                available: true
            },
            {
                name: 'Comments & Feedback',
                available: true
            },
            {
                name: 'My Groups',
                available: true
            },
            {
                name: 'Group Feed',
                available: true
            },
            {
                name: 'Group Reports',
                available: true
            },
            {
                name: 'Create & Manage Groups',
                available: true
            },
            {
                name: 'Send Group Notifications',
                available: true
            },
            {
                name: 'Reflection Coaching Opportunities',
                available: true
            }
        ]
    }
]