// My Reflections Comment Functions



// Get the st, nd, rd
export function getOrdinal(n) {
    let ord = ["st", "nd", "rd"]
    let exceptions = [11, 12, 13]
    let nth = 
    ord[(n % 10) - 1] == undefined || exceptions.includes(n % 100) ? "th" : ord[(n % 10) - 1]
    return n + nth
}

export function replaceLastComma(csv,newValue){

    var ax=csv.lastIndexOf(',');
    
    if(ax!=-1 ){
        csv=csv.substring(0,ax)+ newValue +csv.substring(ax+1);
    }

    return csv;

}