<template>
  <div>
    <!-- HERO INTRO -->
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 bg-gradient-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-5 mt-lg-2 mt-0">
                <h1 class="display-3 text-white">
                  A beautiful, simple to use self-reflection app
                  <span>
                    that will improve your self-awareness &amp; 
                    power up your self-improvement
                  </span>
                </h1>
                
              </div>
              <div class="col-lg-7 mt-lg-0 mt-4">

                <img v-lazy="'img/screens/app-loader.png'" class="img-fluid mb-3" alt="My Reflections Image" />

              </div>
            </div>
            <div class="row">

              <div class="col-12 text-center pt-5">

                  <router-link class="m-0" to="/register">
                    <img class='play-button m-0' alt='Start today' src='/img/brand/mr-start-today.png '/>
                  </router-link>

                  <a class="m-0" href='https://play.google.com/store/apps/details?id=app.myreflections.www'>
                    <img class='play-button m-0' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                  </a>

              </div>

            </div>
          </div>
        </div>
      </section>

    </div>

    <!-- KEY BENEFITS -->
    <section class="section section-lg pt-lg-0 mt--200">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <icon name="fad fa-head-side-brain" type="primary" rounded class="mb-4">
                  </icon>
                  <h6 class="text-primary text-uppercase font-weight-bold">
                    Self-reflection
                  </h6>
                  <p class="description mt-3">
                    Self-reflection will enable you to maximise the experience that
                    you gain from every situation and related decisions, allowing you
                    to identify areas of strength and weakness and empowering you to
                    choose to make adjustments
                  </p>
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <icon name="fad fa-heart-circle" type="primary" rounded class="mb-4">
                  </icon>
                  <h6 class="text-primary text-uppercase font-weight-bold">
                    Self-awareness
                  </h6>
                  <p class="description mt-3">
                    Through reflection, you develop a stronger sense of self-awareness,
                    especially related to why and how you make decisions and what will
                    allow you to put yourself in positive situations that will progress
                    your skills and knowledge
                  </p>
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <icon name="fad fa-trophy" type="primary" rounded class="mb-4">
                  </icon>
                  <h6 class="text-primary text-uppercase font-weight-bold">
                    Self-improvement
                  </h6>
                  <p class="description mt-3">
                    Effective reflection speeds up the self-improvement cycle by allowing
                    contextual insights, that are removed from emotional responses, and
                    can be used to adjust your decision-making processes to ensure
                    positive outcomes
                  </p>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- EXPLAINER VIDEO -->
    <section class="section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-light">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row align-items-center">
          <div class="col-12">

              <div class="shadow shadow-lg">

                <div class="vimeoPlayer" ref="vimeoPlayer"></div>
                <div @click="loadExplainer" v-if="!playVideo">
                  <img src="/img/this-is-my-reflections.png" class="img-fluid pointer hover" />
                </div>

                <!-- <vue-plyr>
                  <div class="plyr__video-embed">
                    <iframe
                      src="https://player.vimeo.com/video/549849917?loop=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media&amp;autoplay=true"
                      allowfullscreen
                      allowtransparency
                      allow="autoplay"
                      autoplay
                    ></iframe>
                  </div>
                </vue-plyr> -->

              </div>

          </div>
        </div>
      </div>
    </section>

    <!-- WHATS SO IMPORTANT ABOUT REFLECTION -->
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-2">
            <img src="/img/theme/creative-thinking.svg" alt="Creative Thinking" class="img-fluid floating" />
          </div>
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">

              <h3 class="feature-title text-info font-weight-bold">
                What is reflection &amp; why is it so important?
              </h3>

              <p>
                Reflection is “the deliberate act of considering decisions, actions and
                outcomes with the intent of learning from them for personal, professional
                or team development, improvement and growth” - <a href="https://www.linkedin.com/in/chriswinfieldblum/" target="_blank">Chris Winfield-Blum <i class="fab fa-linkedin-in"></i></a>.
              </p>

              <p>
                Research and anecdotal evidence suggests that reflection, especially
                self-reflection, is a critical aspect of developing oneself and
                self-awareness while also having a significant influence on self-improvement.
              </p>

              <p>
                Consistent reflection can be a truly powerful tool to level
                yourself up and <span class="text-primary font-weight-bold"><i>My Reflections</i></span>
                provides a simple to use and understand framework.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-secondary">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-12">
            <div class="d-flex px-3">
              <div>
                <icon
                  name="fad fa-dumbbell"
                  size="lg"
                  class="bg-gradient-primary"
                  color="secondary"
                  shadow
                  rounded
                ></icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-primary">
                  How does reflection influence self-improvement?
                </h4>
              </div>
            </div>
          </div>
          <div class="col-md-6 order-lg-1 ml-lg-auto">
            <div class="d-flex px-3">
              <div class="pl-4">
                <p class="text-default">
                  A simple way to visualise how reflection influences self-improvement is
                  <b>“the action-observation-reflection model”</b>.
                </p>
                <p class="text-default">
                  We develop ourselves through actions, observations and reflections;
                  with knowledge, expertise and effectiveness increasing the more
                  cycles we experience these three principles.
                </p>
              </div>
            </div>
            <div class="position-relative pl-md-5">
              <img src="/img/aor-model.png" alt="AOR Model" class="img-center img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 order-lg-2">
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon
                    name="fad fa-tasks"
                    gradient="success"
                    color="white"
                    shadow
                    rounded
                  ></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-success">Actions...</h5>
                  <p>
                    should be thought of as the results of our decisions when put in any
                    situation in our personal or professional lives.
                  </p>
                </div>
              </div>
            </card>
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon
                    name="fad fa-poll-h"
                    gradient="info"
                    color="white"
                    shadow
                    rounded
                  ></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-info">Observations...</h5>
                  <p>
                    should be thought of as noting the outcomes of those actions. Were
                    they successful? Did they fail? Somewhere in between?
                  </p>
                </div>
              </div>
            </card>
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon
                    name="fad fa-head-side-brain"
                    gradient="primary"
                    color="white"
                    shadow
                    rounded
                  ></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-primary">Reflections...</h5>
                  <p>
                    are the processes of evaluating our actions and subsequent observations
                    with the intent of making better decisions in the future
                  </p>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </section>

    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-dark">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-md-5 order-lg-2 ml-lg-auto">
            <div class="position-relative pl-md-5">
              <img src="/img/ill/ill-2.svg" alt="Our Innovative Approach" class="img-center img-fluid" />
            </div>
          </div>
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon
                  name="fad fa-lightbulb-on"
                  size="lg"
                  class="bg-gradient-default"
                  color="secondary"
                  shadow
                  rounded
                ></icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">Our Innovative Approach</h4>
                <p class="text-white">
                  <span class="font-weight-bold"><i>My Reflections</i></span>
                  uses innovative approaches to building habits of reflection and provide
                  you with intuitive, actionable feedback that will change your life!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <feature 
        bg="bg-gradient-default"
        image="img/screens/reflect-now.png"
        type="dark"
        image-class=""
        view="textfirst"
        >

        <template v-slot:title>
          Reflect Now
        </template>

        <template v-slot:subtext>
          Capture timely, consistent reflections
        </template>

        <template v-slot:contentbody>
          My Reflections empowers you to capture activity details
          for more timely reflection and to begin the process of
          identifying high-lights and low-lights for future, deep
          reflection sessions through your weekly reflections.
        </template>

    </feature>

    <feature 
        bg="bg-gradient-white"
        image="img/screens/activity-types.png"
        type="light"
        image-class="rounded shadow"
        view="textsecond"
        >

        <template v-slot:title>
          Activity Types &amp; Tags
        </template>

        <template v-slot:subtext>
          Log reflections by activity types &amp; your own tags
        </template>

        <template v-slot:contentbody>
          Activity types allow you to categorise activities logically
          and will give insights into overall areas of possible
          improvement. Additionally, you can create your own tags
          for more granular insights and tag your activities accordingly.
        </template>

    </feature>

    <feature 
        bg="bg-gradient-secondary"
        image="img/screens/reflection-goals.png"
        type="light"
        image-class="rounded shadow"
        view="textfirst"
        >

        <template v-slot:title>
          Reflection Goals
        </template>

        <template v-slot:subtext>
          Create &amp; track refletion goals &amp; keep focused 
        </template>

        <template v-slot:contentbody>
          Reflection goals allow you to define and track your personal
          objectives and through performing self-reflections not only achieve
          better results but track your performance over the targeted
          timeframe that you have set yourself.
        </template>

    </feature>

    <feature 
        bg="bg-gradient-primary"
        image="img/screens/weekly-reflections.png"
        type="dark"
        image-class=""
        view="textsecond"
        >

        <template v-slot:title>
          Weekly Reflections
        </template>

        <template v-slot:subtext>
          Take weekly deep dives &amp; retrospectives
        </template>

        <template v-slot:contentbody>
          Deep dives, as part of your personal weekly retrospectives, allow
          you to review your weeks' activities, refection statistics 
          and trends while answering consistent questions and identifying
          your weekly highlights, lowlights and adjustments for the upcoming week.
        </template>

    </feature>

    <feature 
        bg="bg-gradient-white"
        image="img/screens/group-reflections.png"
        type="light"
        image-class=""
        view="textfirst"
        >

        <template v-slot:title>
          Group Reflections
        </template>

        <template v-slot:subtext>
          Start reflecting in groups for even more insight
        </template>

        <template v-slot:contentbody>
          Create a group and invite
          friends, colleagues or family to join you on your reflection
          journey <span class="badge badge-default">PRO</span> or join an existing group; share insights, motivational content,
          and run surveys all while encouraging each other with comments &amp; reactions.
        </template>

    </feature>

    <feature 
        bg="bg-gradient-dark"
        image="img/screens/reflection-bot.png"
        type="dark"
        image-class=""
        view="textsecond"
        >

        <template v-slot:title>
          <i class="fad fa-robot"></i> Reflection Bot
        </template>

        <template v-slot:subtext>
          Use A.I to for deeper insights &amp; reflections
        </template>

        <template v-slot:contentbody>

          Using social and linguistic algorithms powered by A.I and
          machine learning technologies, <i class="fad fa-robot"></i> Reflection Bot
          will provide you deeper insights into the tone, polarity
          and subjectivity of your reflections so that you can challenge yourself more 
          effectively on your decisions and their outcomes.

        </template>

    </feature>

    <feature 
        bg="bg-gradient-white"
        image="img/screens/reflection-automation.png"
        type="light"
        image-class=""
        view="textfirst"
        >

        <template v-slot:title>
          <i class="fad fa-plug"></i> Reflection Automation <span class="badge badge-default">PRO</span>
        </template>

        <template v-slot:subtext>
          Automate your reflections to make life easier
        </template>

        <template v-slot:contentbody>

          Connect My Reflections to any service that supports outbound
          webhooks to automatically create a reflection for you when you;
          finish an appointment, complete a task, make a sale, complete a workout
          or a run to name a few! You will then be notified to complete
          the reflection if required.

        </template>

    </feature>

    <!-- What are people saying about My Reflections? -->
    <what-people-say />


    <!-- START A REFLECTION -->
    <section class="section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-3 bg-gradient-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container pt-lg pb-300">
        <div class="row text-center justify-content-center">
          <div class="col-lg-10">
            <h2 class="display-3 text-white">Start a reflection right now!</h2>
            <p class="lead text-white">
              It's easy to get starting with
              <span class="font-weight-bold"><i>My Reflections</i></span
              >, in fact, you can start right now!
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- REFLECT PANEL -->
    <section class="section section-lg pt-lg-0 section-contact-us" id="get-started">
      <div class="container">
        <div class="row justify-content-center mt--300">
          <div class="col-lg-10">
            <card shadow>
              <reflection name="header"></reflection>
            </card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";

import Reflection from "@/components/reflections/Reflection";
import Feature from "@/components/landing/Feature";
import WhatPeopleSay from '@/components/landing/WhatPeopleSay.vue';
// import ReflectionQuotes from '@/components/landing/ReflectionQuotes.vue';

import Player from '@vimeo/player'

export default {
  name: "landing-page",
  metaInfo: {
    title: 'Self-reflection. Self-awareness. Self-improvement.',
  },
  data() {
    return {
			videoID: '549849917',
      player: null,
      playVideo: false
    };
  },
  components: {
    Feature,
    Reflection,
    WhatPeopleSay
    // ReflectionQuotes
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),
  },
  methods: {

    loadExplainer() {

      this.playVideo = true;

      this.player = new Player( this.$refs.vimeoPlayer , {
          id: this.videoID,
          responsive: true,
				  // muted: true,
				  loop: true,
          color: '8d6c9f',
          autoplay: true,
      })
      .on("ended", function() {
            // console.log('video ended!')
        })
    }
  },
  mounted() {
    // 
  },
};
</script>

<style scoped>

  .page-title {
    font-size: 6.5vh;
    font-weight: 500;
    line-height: 1em;
    font-family: 'Caveat';
  }

  .feature-title {
    font-size: 4.5vh;
    font-weight: 500;
    line-height: 1em;
    font-family: 'Caveat';
  }

  .play-button {
    height: 100%;
    max-height: 80px;
  }

</style>