<template>
<div>

    <!-- HERO INTRO -->
    <div class="position-relative">
        <!-- shape Hero -->
        <section class="section-shaped my-0">
            <div class="shape bg-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container shape-container d-flex mt--100">
                <div class="col px-0">
                    <div class="row justify-content-center">
                        <div class="col-lg-12">
                            <img class="img-fluid  shadow" src="/img/myreflections-self-reflection-livechats-banner.png" alt="Reflection Intro - Live Chats" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 1st Hero Variation -->
    </div>

    <!-- KEY BENEFITS -->

    <section class="section section-lg pt-lg-0 mt--300">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <card shadow class="bg-white mt-3 text-left lead">

                      <p class="lead">
                        If you are interested in understanding My Reflections, self-reflection and self-improvement do not
                        hesitate to book a one-on-one with Chris Winfield-Blum.
                      </p>
                      <p class="lead">
                        We can talk about any of the below;
                      </p>

                      <ol>
                        <li>What is self-reflection and why is it important?</li>
                        <li>How to get started with self-reflection?</li>
                        <li>How does reflection influence learning?</li>
                        <li>How does reflection influence self-awareness?</li>
                        <li>How does reflection influence self-improvement?</li>
                      </ol>

                      <p class="lead">
                        I’m also more than happy to treat these as short interviews for content providers in the
                        self-improvement space, just let me know when you book your appointment and any specific
                        topics/questions you would like to cover. We can have the session recorded for your reference or editing too 😊
                      </p>

                      <p class="lead">
                        Check the availability below and book a time with me, the founder and creator of My Reflections!
                      </p>

                    </card>
                </div>
  
            </div>
        </div>
        <vue-calendly url="https://calendly.com/myreflections/reflection-intro?primary_color=7c0f80" :height="800"></vue-calendly>
    </section>

</div>
</template>

<script>
// import FAQ from "@/reflection-components/FAQ";
import firebase from 'firebase/app'
import {
    db
} from '@/firebase';
import store from '@/store.js'

export default {
    name: "page",
    metaInfo: {
      title: 'Reflection Introduction - Live Chats',
      meta: [
        {
          vmid: "title",
          name: "title",
          content: "Reflection Introduction - Live Chats!",
        },
        {
          vmid: "description",
          name: "description",
          content:
            "Register for a one-on-one session with Chris from My Reflections and help get your self-reflection journey started!",
        },
        // FACEBOOK / GRAPH
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://myreflections.app/reflection-intro",
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: "Reflection Week - Live Chats!",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content:
            "Register for a one-on-one session with Chris from My Reflections and help get your self-reflection journey started!",
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: "https://myreflections.app/img/myreflections-self-reflection-livechats-banner.png",
        },
        // TWITTER
        {
          vmid: "twitter:card",
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          vmid: "twitter:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "twitter:url",
          property: "twitter:url",
          content: "https://myreflections.app/reflection-intro",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: "Reflection Week - Live Chats!",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content:
            "Register for a one-on-one session with Chris from My Reflections and help get your self-reflection journey started!",
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: "https://myreflections.app/img/myreflections-self-reflection-livechats-banner.png",
        },
      ],
    },
    data() {

        return {
            slug: null,
            content: {}
        }

    },
    created() {

    },
    watch: {

    },
    mounted() {
        
    },
    methods: {

    }

};
</script>

<style>
#ourContent img {
    display: block;
    margin: auto;
    text-align: center;
    max-width: 100%;
}

.my-image {
    max-height: 350px;
}

.tile-image {
    text-align: center;
}

.tile-image-sm {
    max-height: 100px;
}
</style>
