export default [
  {
    name: "appointment",
    image: "/img/images/reflectionTypes/rtAppointment.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your appointment?',
        scheduleintro: 'What is this appointment for?',
        activity: 'Describe this appointment...',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "event",
    image: "/img/images/reflectionTypes/rtEvent.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your event?',
        scheduleintro: 'What is this event?',
        activity: 'Describe this event',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "game",
    image: "/img/images/reflectionTypes/rtGame.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your game?',
        scheduleintro: 'What game?',
        activity: 'Describe the game...',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "learning",
    image: "/img/images/reflectionTypes/rtLearning.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your learning activity?',
        scheduleintro: 'What are you learning about?',
        activity: 'Describe what you learned about...',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "meeting",
    image: "/img/images/reflectionTypes/rtMeeting.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your meeting?',
        scheduleintro: 'What meeting?',
        activity: 'Describe this meeting or what it was for...',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "personal",
    image: "/img/images/reflectionTypes/rtPersonal.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your personal activity?',
        scheduleintro: 'What is the personal activity?',
        activity: 'Describe this personal activity...',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "project",
    image: "/img/images/reflectionTypes/rtProject.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your project activity?',
        scheduleintro: 'What project activity?',
        activity: 'Describe this project or the activity?',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "sleep",
    image: "/img/images/reflectionTypes/rtSleep.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'How was your sleep?',
        scheduleintro: 'Give your sleep a unique label',
        activity: 'Label',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "task",
    image: "/img/images/reflectionTypes/rtTask.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your task?',
        scheduleintro: 'What task?',
        activity: 'Describe this task...',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "training",
    image: "/img/images/reflectionTypes/rtTraining.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your training?',
        scheduleintro: 'What is the training?',
        activity: 'Decribe the training activity...',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "wellness",
    image: "/img/images/reflectionTypes/rtWellness.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your wellness activity?',
        scheduleintro: 'What wellness activity?',
        activity: 'Describe this wellness activity...',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "general",
    image: "/img/images/reflectionTypes/rtGeneral.svg",
    visible: true,
    special: false,
    license: 0,
    labels: {
        introduction: 'What and how was your activity?',
        scheduleintro: 'What activity?',
        activity: 'Describe the activity you completed...',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "daily checkin",
    image: "/img/images/reflectionTypes/rtDailyCheckin.svg",
    visible: true,
    special: true,
    license: 0,
    labels: {
        introduction: 'Start your daily checkin?',
        scheduleintro: 'Give it a name or label',
        activity: 'Describe this checkin...',
        rating: 'Overall, how do you rate your day?',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "journal entry",
    image: "/img/images/reflectionTypes/rtJournalEntry.svg",
    visible: true,
    special: true,
    license: 0,
    labels: {
        introduction: 'What would you like to journal about?',
        scheduleintro: 'Give it a name or label',
        activity: 'Start writing...',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
  {
    name: "photo journal",
    image: "/img/images/reflectionTypes/rtPhotoJournal.svg",
    visible: true,
    special: true,
    license: 1,
    labels: {
        introduction: 'What is this image and how do you feel about it?',
        scheduleintro: 'What is this image for?',
        activity: 'Label your image',
        rating: 'Rate it out of 10',
        highlows: 'What were the highlights and low-lights'
    }
  },
];
