/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

*/

import './firebase';

import Vue from "vue";
import firebase from 'firebase/app'
import "firebase/analytics";
import { firestorePlugin } from 'vuefire'
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import '@/plugins/Dayjs';
import '@/plugins/myReflectionsCommon';
import { BootstrapVue } from 'bootstrap-vue'
import './registerServiceWorker';
import VueToast from 'vue-toast-notification';
import store from "./store";
import StoryblokVue from 'storyblok-vue';
import VueTextareaAutosize from 'vue-textarea-autosize'

import VueCompositionApi from '@vue/composition-api';
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer';

import VueScrollTo from 'vue-scrollto';
import Vue2TouchEvents from 'vue2-touch-events'
import VueDeviceDetecor from 'vue-device-detector'
import VueMeta from 'vue-meta'
import UUID from "vue-uuid";
import Trend from "vuetrend"

import CoolLightBox from 'vue-cool-lightbox'

import VueLazyload from 'vue-lazyload'

// PROFILE IMAGE UPLOAD
import VueRx from 'vue-rx'
import VuejsClipper from 'vuejs-clipper'

import VueClipboard from 'vue-clipboard2'
import VuePapaParse from 'vue-papa-parse'

import VCalendar from 'v-calendar';

// Slick
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

// CSS
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

import VueCalendly from 'vue-calendly';

// firebase init - add your own config here
let app = '';

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});

/* eslint-disable no-new */
Vue.config.productionTip = false;


// or with options
// const loadimage = require('./assets/loading.gif')
// const errorimage = require('./assets/error.gif')

Vue.use(VueLazyload);
// , {
//   preLoad: 1.3,
//   error: errorimage,
//   loading: loadimage,
//   attempt: 1
// })

// Vue.mixin({
//   data: function() {
//     return {
//       swipeEnabled: true
//     }
//   }
// })

Vue.use(VueCalendly);
Vue.use(VCalendar);
Vue.use(VuePapaParse)
Vue.use(VueClipboard)
Vue.use(Argon)
Vue.use(Trend)
Vue.use(BootstrapVue)
Vue.use(CoolLightBox)
Vue.use(VueSlickCarousel)
Vue.use(firestorePlugin)
Vue.use(VueTextareaAutosize)
Vue.use(StoryblokVue)
Vue.use(VueCompositionApi)
Vue.use(VueRichTextRenderer)
Vue.use(VueScrollTo)
Vue.use(Vue2TouchEvents)
Vue.use(VueDeviceDetecor)
Vue.use(UUID)
Vue.use(VueMeta)
Vue.use(VueRx)
Vue.use(VuejsClipper)
Vue.use(VueToast, {
  // One of the options
  position: 'top',
  queue: true
})
Vue.use(require('vue-slugify'));
Vue.prototype.$analytics = firebase.analytics();

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

app = new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");




